import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Breadcrumbs are a sequence of links typically displayed at the top of a webpage or app and help users understand where they are in the overall hierarchy and easily navigate back to previous levels.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/breadcrumb/breadcrumb-guideline-anatomy.svg",
      "alt": "anatomy button guideline"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Separator`}</strong>{`: A character (often ’›’ or ’/’) that separates each breadcrumb link.`}</li>
      <li parentName="ol"><strong parentName="li">{`Breadcrumb Links`}</strong>{`: Links representing each level of the hierarchy. They are typically styled as text or icons.`}</li>
      <li parentName="ol"><strong parentName="li">{`Dropdown(Optional)`}</strong>{`: A dropdown containing links to each level of hierarchy`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Breadcrumbs component is commonly used in web and application design for the following purposes:`}</p>
    <ul>
      <li parentName="ul">{`Website Navigation: Breadcrumbs help users navigate websites efficiently, especially when a site has a deep hierarchical structure.`}</li>
      <li parentName="ul">{`Application Navigation: In applications with multiple screens or sections, breadcrumbs guide users through the app’s hierarchy.`}</li>
      <li parentName="ul">{`E-commerce: Breadcrumbs are essential in e-commerce websites, showing the product category path.`}</li>
      <li parentName="ul">{`Documentation: In documentation websites, breadcrumbs show the hierarchical structure of articles, making it easier for users to find relevant content.`}</li>
    </ul>
    <p><strong parentName="p">{`Common places breadcrumbs appear`}</strong>{` : Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        On any page that is more than two levels deep in the website's hierarchy
      </li>
      <li>On pages with complex navigation structures</li>
      <li>
        On pages where users are likely to be coming from different entry points
      </li>
      <li>
        On pages where users need to be able to easily backtrack to previous
        levels{" "}
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>On the homepage</li>
      <li>On pages with very simple navigation structures</li>
      <li>On pages where users are not likely to need to backtrack</li>
      <li>In pop-ups or modal windows</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`To ensure accessibility, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Make sure that all links in the breadcrumb trail are keyboard accessible.`}</li>
      <li parentName="ul">{`Use clear and concise link text.`}</li>
      <li parentName="ul">{`Add an aria-label attribute to the breadcrumb trail to provide a descriptive label for screen reader users.`}</li>
      <li parentName="ul">{`Use the aria-current=“page” attribute to indicate the current page in the breadcrumb trail to screen reader users.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Separate each link in the breadcrumb trail with a separator character to make it clear and concise." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/breadcrumb/breadcrumb-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Didn’t separate each link in the breadcrumb trail with a separator so it confusing." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/breadcrumb/breadcrumb-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When implementing breadcrumb content, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`Keep breadcrumb text concise and informative, indicating the content’s hierarchy.`}</li>
      <li parentName="ul">{`Use a maximum of two lines for breadcrumb text to avoid visual clutter.`}</li>
      <li parentName="ul">{`If necessary, abbreviate or truncate breadcrumb text to fit the available space while ensuring that it remains understandable.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use breadcrumb trail on pages that had many level deep in the website’s hierarchy" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/breadcrumb/breadcrumb-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use the breadcrumb trail on pages that are only one level deep in the website's hierarchy." aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/breadcrumb/breadcrumb-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      